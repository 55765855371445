<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Tedarikçi</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Tedarikçi Ekle</span>
      </h3>
      <v-col class="text-right" v-if="id">
        <rs-action @click="handleAddToClustersClick"> TYA'lara Ekle </rs-action>

        <rs-action-delete
          v-if="can('edit-company')"
          @click="handleDeleteClick(item)"
          confirmed
          title="Sil"
          icon
        />

        <rs-action
          @click="showActionLogs"
          v-if="id && can('edit-company')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>{{ $t("headers.info") }}</v-tab>
        <v-tab to="#cluster-providers" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="clusterProviderCount"
          >
            TYA Tedarikçileri
          </v-badge>
        </v-tab>
        <!-- <v-tab to="#notes" replace :disabled="!id">
            {{ $t("headers.notes_and_reminders") }}
          </v-tab>
          <v-tab to="#documents" replace :disabled="!id">
            {{ $t("headers.documents") }}
          </v-tab> -->
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form @submit.prevent="handleFormSubmit" ref="form">
            <v-row dense>
              <v-col sm="4">
                <rs-select
                  :items="providerTypeList"
                  :rules="[rules.required]"
                  :label="$t('labels.provider_type')"
                  v-model="formData.provider_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col sm="4">
                <rs-text-field
                  :label="$t('labels.company_short_name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 120)]"
                  v-model="formData.name"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col sm="4">
                <rs-text-field
                  :label="$t('labels.company_title')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.title, 200),
                  ]"
                  v-model="formData.title"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-select
                  :label="$t('labels.income_expense_type')"
                  :rules="[rules.required]"
                  v-model="formData.expense_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :items="fullExpenseTypeList"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-select
                  :items="bankWorkflowCollectionTypeOptions"
                  :item-text="(item) => $t(item.translation_key)"
                  item-value="id"
                  :label="$t('labels.bank_workflow_payment_type')"
                  v-model="formData.bank_workflow_type_id"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-select-bank
                  v-model="formData.bank_1_id"
                  :rules="[]"
                  :label="$t('labels.bank_1')"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.bank_1_iban')"
                  :rules="[rules.iban]"
                  v-model="formData.bank_1_iban"
                  :readonly="disabled"
                  :filled="disabled"
                  v-mask="'TR ## #### #### #### #### #### ##'"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-select-bank
                  v-model="formData.bank_2_id"
                  :rules="[]"
                  :label="$t('labels.bank_2')"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.bank_2_iban')"
                  :rules="[rules.iban]"
                  v-model="formData.bank_2_iban"
                  :readonly="disabled"
                  :filled="disabled"
                  v-mask="'TR ## #### #### #### #### #### ##'"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.tax_office')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.tax_office, 20),
                  ]"
                  v-model="formData.tax_office"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.tax_number')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.tax_number, 20),
                  ]"
                  v-model="formData.tax_number"
                  :readonly="disabled"
                  :filled="disabled"
                /> </v-col
              ><v-col cols="12" sm="6">
                <rs-select-location
                  :label="$t('labels.city')"
                  :rules="[rules.required]"
                  v-model="formData.city_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="2"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select-location
                  :label="$t('labels.district')"
                  :rules="[rules.required]"
                  v-model="formData.district_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="3"
                  :parent-id="formData.city_id"
                  required
                  :disabled="!formData.city_id"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-tel-field
                  :label="$t('labels.company_phone')"
                  v-model="formData.phone"
                  :rules="[rules.numbersOnlyWithParam]"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.company_email')"
                  type="email"
                  :rules="[rules.email, rules.maxLength(formData.email, 120)]"
                  v-model="formData.email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.is_active"
                  :label="$t('labels.is_active')"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.create_on_new_cluster"
                  :label="$t('labels.create_on_new_cluster')"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-if="!id"
                  v-model="formData.apply_to_current"
                  label="Mevcut TYA tanımlarına ekle"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>

              <v-col cols="12">
                <rs-bank-keywords
                  v-model="formData.bank_keywords"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :isLoading="isLoading"
              @cancel="handleCancelClick"
              v-if="!disabled"
              @submit.prevent="handleFormSubmit"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="cluster-providers" class="pa-4" :eager="id">
          <ProviderList
            v-if="id"
            :parent-id="id"
            title="TYA Tedarikçileri"
            :item-count.sync="clusterProviderCount"
            show-balance
            show-search
            ref="providerList"
          />
        </v-tab-item>
        <!-- <v-tab-item value="notes" class="pa-4">
          <NoteList :allow-add="true" :extra-params="{ provider_id: id }" />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4">
          <DocumentList :allow-add="true" :extra-params="{ provider_id: id }" />
        </v-tab-item> -->
      </v-tabs-items>

      <AddProviderToClustersForm
        :extra-params="{ provider_id: id }"
        @saved="load() || $refs.providerList.loadList()"
        :provider="originalData"
        ref="addProviderToClustersForm"
      />
    </v-card-text>
    <ActionLogList ref="actionLogList" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { bankWorkflowCollectionTypeOptions } from "@/core/data/options.js";
import { hasForm, hasPermissions } from "@/view/mixins";
// import DocumentList from "@/view/content/documents/DocumentList";
// import NoteList from "@/view/content/Notes/NoteList";
import AddProviderToClustersForm from "@/view/content/providers/AddProviderToClustersForm";
import ProviderList from "@/view/content/companies/ProviderList";

export default {
  components: {
    ActionLogList,
    AddProviderToClustersForm,
    // DocumentList,
    // NoteList,
    ProviderList,
  },
  mixins: [hasForm, hasPermissions],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "bankIntegrationTypeList",
      "companyId",
      "fullExpenseTypeList",
      "providerTypeList",
    ]),
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      originalData: {},
      clusterProviderCount: null,
      formData: {
        provider_type_id: null,
        name: null,
        title: null,
        expense_type_id: null,
        bank_workflow_type_id: 2,
        bank_integration_type_id: null,
        bank_1_id: null,
        bank_1_iban: null,
        bank_2_id: null,
        bank_2_iban: null,
        tax_office: null,
        tax_number: null,
        city_id: null,
        district_id: null,
        phone: null,
        email: null,
        create_on_new_cluster: true,
        apply_to_current: false,
        is_active: true,
        bank_keywords: [],
      },
      bankWorkflowCollectionTypeOptions: bankWorkflowCollectionTypeOptions,
      bankWorkflowPaymentTypeOptions: bankWorkflowCollectionTypeOptions,
    };
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
  methods: {
    ...mapActions(["updateCompanyId"]),
    load() {
      this.isLoading = true;

      this.$api
        .query(`company-providers/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (formData.fax) {
        formData.fax = this.formData.fax.replace(/[^0-9+]/g, "");
      }

      if (formData.concern_phone) {
        formData.concern_phone = this.formData.concern_phone.replace(
          /[^0-9+]/g,
          ""
        );
      }

      if (formData.bank_1_iban) {
        formData.bank_1_iban = this.formData.bank_1_iban.replace(
          /[^0-9TR+]/g,
          ""
        );
      }

      if (formData.bank_2_iban) {
        formData.bank_2_iban = this.formData.bank_2_iban.replace(
          /[^0-9TR+]/g,
          ""
        );
      }

      this.isLoading = true;

      if (this.id) {
        this.$api
          .put(`company-providers/${this.id}`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.provides_id = this.companyId;

        this.$api
          .post(`company-providers`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.updateCompanyId(response.data.data.provides_id);

            this.$router.replace({
              name: "my-company.providers.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`company-providers/${this.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.$router.replace({
            name: "my-company.providers.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleAddToClustersClick() {
      this.$refs.addProviderToClustersForm.show({ provider_id: this.id });
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Provider", this.id);
    },
  },
};
</script>
